import React from 'react'
import "../Styles/Home.css"
import Body from "../Components/Body"
const Home = () => {
  return (
    <div className='home'>

      <Body/>

    </div>
  )
}

export default Home
